#create-edit__product .related-product {
  $line-height: 12px;

  .title {
    position: relative;
    width: 160px;
    text-align: right;
    flex-shrink: 0;
    white-space: nowrap;
    top: calc(($line-height + 8px) / 2 * -1);
  }

  .desc {
    font-size: 10px;
    line-height: $line-height;
  }

  .multi-select-wrap {
    max-width: 1000px;
  }
}
