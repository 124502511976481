#create-edit__product .header {
  .title {
    flex-shrink: 0;

    &.center {
      position: relative;
      top: 23px;
      transform: translateY(-50%);
    }

    &.no-width {
      width: unset;
    }
  }

  .store-list {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 12px 24px;
  }

  .input-field {
    flex-grow: 1;
    min-width: 300px;
    max-width: 300px;
    padding: 11px 16px;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 10px;
    outline: none;
    transition: all 0.3s linear;

    &:focus {
      border-color: var(--primary) !important;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }

    &:disabled {
      background-color: #f4f4f4;
      pointer-events: none;
    }

    &.large {
      max-width: 900px;
    }
  }

  .price-wrap {
    @extend .input-field;
    width: 300px;
    padding: 0 !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.4);
    height: 48px;
    overflow: hidden;
    transition: border-color 0.3s linear;
    will-change: border-color;

    &.tier {
      min-width: unset;
      width: 200px;
    }

    &.disable {
      background-color: #f4f4f4;
      pointer-events: none;
    }

    &:focus-within {
      border-color: var(--primary) !important;
    }

    span {
      user-select: none;
      position: relative;

      &::before {
        $space: 13px;
        content: '';
        position: absolute;
        top: $space;
        bottom: $space;
        right: 0;
        width: 1px;
        background-color: currentColor;
      }
    }

    input {
      outline: none;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
      }
    }
  }
  .done-wrap {
    color: var(--primary);
    position: relative;

    .button {
      z-index: 1;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: currentColor;
      opacity: 0.05;
    }
  }

  $width-price-type: 40%;
  .select-custom {
    width: $width-price-type;
    height: 48px;
  }

  .error-price {
    margin-left: calc($width-price-type + 16px);
  }

  .weight-wrap {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 10px;
    width: 300px;
    height: 48px;
    overflow: hidden;
    transition: border-color 0.3s linear;

    &.disable {
      background-color: #f4f4f4 !important;
    }

    &:focus-within {
      border-color: var(--primary) !important;
    }

    span {
      position: relative;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.4);

      &::before {
        $space: 13px;
        content: '';
        position: absolute;
        top: $space;
        bottom: $space;
        right: 0;
        width: 1px;
        background-color: currentColor;
      }
    }

    input {
      outline: none;

      &:disabled {
        background-color: #f4f4f4;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
      }
    }
  }

  .short-desc {
    .content {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      max-width: 1000px;
      display: block;
      height: 100px;
      min-width: 300px;
      resize: none;
      outline: none;
      transition: border-color 0.3s linear;
      will-change: border-color;

      &:focus {
        border-color: var(--primary);
      }
    }
  }

  .adv-price {
    .content {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 2px;
        background-color: #4285f4;
      }
    }

    .content-adv-price {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: fit-content;
      gap: 24px;

      .message {
        max-width: 300px;
      }

      input {
        transition: border-color 0.2s linear;
        will-change: border-color;
      }

      .title {
        width: fit-content !important;
      }
    }
  }

  .select[name^='website_id'],
  .select[name^='customer_group_id'] {
    min-width: 170px;
    padding-block: 11px;
  }

  input[type='date'] {
    width: 300px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    outline: none;
    padding-block: 11px;

    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }

    &:focus {
      border-color: var(--primary);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }

    &:disabled {
      background-color: #f4f4f4;
      cursor: default;
    }
  }

  .adv-inventory {
    .content-adv-inventory {
      width: fit-content;
      margin-left: 80px;

      .title {
        width: fit-content;
        text-align: end;

        &.has-checkbox {
          position: relative;
          top: -14px;
        }
      }

      .field-wrap {
        .input-adv {
          width: 500px !important;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          outline: none;
          padding-block: 12px;

          &:disabled {
            background-color: #f4f4f4;
            cursor: default;
          }
        }
      }
    }
  }

  .mess-err {
    max-width: 300px;
  }

  .mess-err-tier {
    max-width: 180px;
    word-break: keep-all;
  }

  .close-icon {
    font-size: 24px;

    svg {
      transition: color 0.2s linear;
      will-change: color;
    }

    &:hover svg {
      color: var(--danger);
    }
  }

  .trash-icon {
    @extend .close-icon;
    font-size: 20px;
  }

  .qty-tier {
    @extend .input-field;
    min-width: unset !important;
    max-width: 200px !important;
    padding-inline: 12px;
    height: 48px;
  }

  #table-adv-price {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);

    input {
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      box-shadow: none;
      transition: border-color 0.2s linear;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
      }

      &:focus {
        border-color: var(--primary);
      }
    }

    thead tr:first-child {
      th:first-child {
        border-top-left-radius: 5px;
      }
      th:last-child {
        border-top-right-radius: 5px;
      }
    }

    tbody tr:nth-child(even) {
      background-color: #f5f9fe;
    }

    tr {
      th:first-child,
      td:first-child {
        padding-inline: 24px !important;
      }
      th:last-child,
      td:last-child {
        padding-inline: 24px !important;
      }
    }

    th,
    td {
      font-size: 14px;
      line-height: 17px;
      padding-inline: 8px;
      border-right: 1px dashed rgba(0, 0, 0, 0.2) !important;

      &:last-child {
        border-right: none !important;
      }
    }

    th {
      padding-block: 16px;
      border: none;
      background-color: var(--primary);
      color: var(--white);
      font-weight: 500;
    }

    td {
      padding-block: 16px;
      border: none;
      vertical-align: top;
    }
  }
}

@media (max-width: 1500px) {
  #create-edit__product .header {
    //   .start-date-promotion {
    //     display: grid !important;
    //     grid-template-columns: auto;
    //     // flex-direction: column;
    //     // align-items: flex-start !important;
    //     row-gap: 16px;
    //   }
    .label-right,
    .title-subtotal {
      width: var(--width-title-coupon);
    }
    .discount-amount-subtotal {
      row-gap: 16px !important;
    }
  }
}

@media (max-width: 1200px) {
  #create-edit__product .header {
    .weight-and-has-weight {
      flex-direction: column;
      row-gap: 16px;
      align-items: flex-end;
    }
  }
}

@media (max-width: 1200px) {
  #create-edit__product .header {
    .weight-and-has-weight {
      flex-direction: column;
      row-gap: 16px;
      align-items: flex-end;
    }
  }
}

.subtotal-coupon {
  width: 439px !important;
}

.categories-coupon {
  display: flex;
  width: 900px;
}
.validate-coupon {
  color: var(--danger);
  font-size: 14px;
  margin-left: 180px;
  padding-top: 8px;
}

.discout-type {
  margin-left: 72px;
}

.discount-amount {
  margin-left: 40px;
}

.apply-to {
  margin-left: 42px;
}

.discount-amout-input {
  width: 339px;
}
.sub-total-input {
  margin-left: -32px;
}
.sku-line-item {
  margin-left: 149px;
}
.cate-line-item {
  margin-left: 99px;
}
.validate-amount {
  color: var(--danger);
  font-size: 14px;
  margin-left: 7px;
}
.label-div {
  padding: 16px;
  margin-top: -27px;
  font-size: 20px;
  font-weight: 400px;
  font-family: 'Poppins';
  color: #000;
  margin-left: -16px;
}

.max-qty-apply {
  margin-left: 37px;
}
.discount-qty-apply {
  margin-left: 23px;
}

.title-validate {
  position: relative;
  top: 23px;
  height: fit-content;
  transform: translateY(-50%);
}
// .sku-showpopup {
//   padding: 440px !important;
//   width: 100% !important;
//   background-color: white;
// }
// .box-table-end {
//   box-shadow: 1px 0px 0px rgba(0, 0, 0, 1) !important;
//   overflow: hidden !important;
// }

.box-table-start {
  overflow: hidden !important;
}

.sku-selected {
  padding: 5px;
  background: white;
  border-radius: 50px;
  max-width: 100%;
  border: 1px solid #e7e7e7;
  margin-top: -8px;

  .name {
    font-size: 14px;
    line-height: 17px;
    margin-right: 10px;
    word-break: break-all;
  }
}

.color-light {
  color: #e7e7e7;
}

.checkbox-all-custom .checkmark {
  transform: translate(-50%, -50%);
  left: 50%;
  border: 1px solid white !important;
  // &::after {
  //   border: var(--primary) !important;
  // }
}

.btn-custom-height {
  width: 149px !important;
  height: 49px !important;
}

.popup-size-sku {
  width: 1260px;
}
.table-style-sku {
  max-height: 465px;
  height: fit-content;
  overflow: auto;
  padding-left: 1px;
  padding-right: 1px;
}
@media screen and (max-height: 870px) {
  .table-style-sku {
    height: 200px;
  }
  .popup-size-sku {
    height: 400px;
    overflow: auto;
  }
}

@media screen and (max-height: 600px) {
  .table-style-sku {
    height: 100px;
  }
  .popup-size-sku {
    height: 300px;
    overflow: auto;
  }
}

@media screen and (max-width: 1300px) {
  .popup-size-sku {
    font-size: 14px;
  }
}
.bd-sku {
  box-shadow: 0px 0px 1px rgb(0, 0, 0) !important;
}

.resp-select {
  min-width: 100px;
  flex-shrink: 0;
}

.mw-sku {
  max-width: 180px;
}

.custom-gap-tablesku {
  flex-wrap: wrap;
  gap: 16px;
}

.font-convert-filter {
  opacity: 0.6;
  font-weight: 400;
}
.separator-space {
  font-size: 20px;
  margin-top: -5px;
}
