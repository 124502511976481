.review-page {
  .review-item-heading {
    .info .title {
      &:hover {
        color: var(--primary);
      }
    }

    .thumbnail {
      width: 70px;
      height: 70px;
      border-radius: 10px;
    }

    .rating {
      svg {
        width: 12px;
        height: 12px;
      }

      .no-fill {
        color: #d9d9d9;
      }
    }
  }

  .review-item-footer {
    border-top: 1px solid var(--border-color);
  }

  .popup-body {
    padding: 10px 0;
  }

  .review-attachment-img {
    $size: 60px;
    width: $size;
    height: $size;
    object-fit: cover;
    border-radius: 10px;
  }
}
