.menu-horizontal {
  column-gap: 40px;

  .menu-item {
    list-style: none;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: flex-end;
    column-gap: 12px;
    cursor: pointer;
    transition: color 0.2s linear;
    position: relative;

    &::before {
      $size-border: 3px;
      content: '';
      position: absolute;
      top: calc(100% - $size-border);
      left: 0;
      right: 0;
      height: $size-border;
      border-radius: 50rem;
      background-color: transparent;
      transition: background-color 0.2s linear;
    }

    &:hover,
    &.active {
      color: var(--primary);

      &::before {
        background-color: var(--primary);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .menu-horizontal {
    column-gap: 24px;
  }
}

@media screen and (max-width: 750px) {
  .menu-horizontal {
    .menu-icon {
      display: none;
    }
  }
}
