.p-32px {
  padding: 32px !important;
}

.swal2-close:focus {
  box-shadow: none !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-styled.swal2-cancel:focus {
  box-shadow: none !important;
}
