.brand-page {
  $border-color: #e7e7e7;

  .brand-image {
    max-width: calc(100% - 24px);
    width: fit-content;
    max-height: 50px;
    object-fit: cover;
  }

  .brand-item-action {
    border-top: 1px solid $border-color;
  }

  .delete-action,
  .edit-action {
    cursor: pointer;

    &:hover {
      color: var(--primary-hover);
    }
  }

  .delete-action-wrap {
    border-right: 1px solid $border-color;
  }

  .create-edit-form {
    position: sticky;
    top: 24px;
  }

  .upload-brand {
    border-radius: 5px;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    background-color: var(--white);
    height: 124px;
  }

  .preview-brand {
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    background: var(--white);
    box-shadow: 0px 2px 0px 0px var(--primary);
  }

  .preview-brand-image {
    $size: 40px;
    width: $size;
    height: $size;
    object-fit: contain;
  }

  .form-input-wrap {
    &:focus-within {
      border-color: var(--primary);
    }

    input {
      outline: none;
    }
  }
}
