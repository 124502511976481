.logo-img {
  width: 233px;
  height: 71px;
  margin-bottom: 18px;
}

.form-wrap {
  width: 448px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 1px 1px 20px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  position: relative;
  padding: 24px;

  .back-login {
    position: absolute;
    top: 29px;
    left: 28px;
  }

  .separator {
    margin-top: 40px;
  }

  .title {
    font-size: 18px;
    line-height: 22px;
  }
}
