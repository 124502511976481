.pagination-item.pagination {
  &-item {
    height: 26px;
    min-width: 26px;
    padding-inline: 8px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: transparent;
    &:hover {
      transition: all 0.5s ease;
      background-color: #1a25ab !important;
      color: #fff !important;
    }

    &.active {
      background-color: rgba(0, 0, 128, 1) !important;
      color: #fff !important;
    }
  }
}
