.categories-page {
  .folder-wrap {
    border: 1px solid var(--primary);
    opacity: 0.4;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    .folder-img {
      object-fit: contain;
    }
  }

  .toggle-icon-wrap {
    border-radius: 20px;
    border: 1px solid #e7e7e7;
    padding: 6px 4px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;

    .toggle-img {
      transition: all 0.2s linear;
      font-size: 10px;
    }
  }

  .category-item {
    border-radius: 20px;

    &.disabled {
      &.active,
      &:hover {
        background: linear-gradient(90deg, rgba(24, 7, 103, 0.03) 0.08%, rgba(0, 0, 0, 0) 50.12%),
          linear-gradient(90deg, rgba(163, 167, 221, 0.2) 32.2%, rgba(24, 7, 103, 0.2) 99.16%);
      }

      .category-item-left {
        opacity: 0.4;
      }
    }

    &.active,
    &:hover {
      transition: all 0.2s linear;
      background: linear-gradient(144deg, rgba(24, 7, 103, 0.03) 0%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(141deg, rgba(163, 167, 221, 0.2) 0%, rgba(24, 7, 103, 0.2) 100%);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    }

    &:hover .category-action {
      transition: visibility 0.2s linear;
      display: flex !important;
    }
  }

  .category-action:not(.active) {
    display: none !important;
  }

  .info-categories,
  .sub-info-wrap,
  .category-action,
  .utils-action {
    > span {
      padding-right: 8px;
      border-right: 1px solid #e7e7e7;

      &:last-child {
        padding-right: 0;
        border-right: none;
      }
    }
  }

  .list-category-children {
    margin-left: 42px;
    padding-left: 12px;
    border-left: 1px solid #e7e7e7;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: -5px;
      width: 10px;
      height: 8px;
      background-color: var(--white);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  // Create edit form
  .upload-category {
    border-radius: 5px;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    background-color: var(--white);
    padding: 16px auto;
  }

  .upload-placeholder {
    width: 75px;
  }

  .popup-view-products {
    min-height: max(60vh, 400px);
    width: 1400px;
    max-width: 100%;
  }

  .input-filter-custom-short {
    min-width: 80px;
  }

  .select-filter-custom-medium {
    min-width: 150px;
  }

  .table-column-filter > .td-item {
    background-color: #f5f5f5;
  }

  // .table-column-filter.no-border > .td-item {
  //   border: 0 !important;
  // }

  .checkbox-custom-position .checkmark {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .preview-category {
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    background: var(--white);
    box-shadow: 0px 2px 0px 0px var(--primary);
  }

  .preview-category-image {
    $size: 40px;
    width: $size;
    height: $size;
    object-fit: cover;
  }

  .form-input-wrap {
    &:focus-within {
      border-color: var(--primary);
    }

    span {
      border-right: 1px solid #e7e7e7;
    }

    &.disabled,
    & > input:disabled {
      background-color: var(--disabled) !important;
    }

    input {
      outline: none;
    }
  }

  .form-heading {
    &:hover {
      color: var(--primary-hover);
    }
  }
}

@media screen and (max-width: 1440px) {
  .categories-page {
    .list-category-children {
      margin-left: 24px;
    }

    .category-item {
      &.active,
      &:hover {
        .info-categories {
          flex-direction: column !important;
          align-items: start !important;

          .category-name {
            border: none !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .categories-page {
    .category-item {
      &.active,
      &:hover {
        .info-categories {
          flex-direction: column !important;

          .category-name {
            border: none !important;
          }
        }
      }
    }
  }
}
