img[alt='mobile_image'] {
  display: none !important;
}

.back-product-list {
  padding-block: 12.5px;
  color: var(--primary);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  transition: color 0.2s linear, background-color 0.2s linear, border-color 0.2s linear;
  border: 1px solid currentColor;

  &:hover {
    border-color: var(--primary-hover);
    background-color: var(--primary-hover);
    color: var(--white);
  }
}

input {
  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset;
    -webkit-box-shadow: 0 0 0 1000px white inset;
  }
}

#create-edit__product {
  .wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 40px;
  }

  .popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    inset: 0px;
    z-index: 9999;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }

  .popup-content {
    width: 95%;
    height: 95%;
    overflow: auto;
    position: relative;
  }

  // .title {
  //   font-weight: 400;
  //   font-size: 16px;
  //   line-height: 20px;
  // }

  .checkbox-custom {
    input {
      $size: 20px;
      width: $size;
      height: $size;

      &:checked {
        accent-color: var(--primary);
      }

      &:disabled {
        cursor: default;
      }
    }

    label.disabled {
      cursor: default;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .heading-text {
    font-size: 20px;
    line-height: 24px;
    transition: color 0.2s linear;
    width: fit-content;

    svg {
      font-size: 18px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
