.overview-item {
  border-radius: 20px;
  row-gap: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.7s;
  background-color: #fff;

  &:hover {
    background: linear-gradient(180deg, rgba(24, 7, 103, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
    cursor: pointer;
  }
  .item {
    padding: 10px 20px;
    .qty {
      font-size: 40px;
      line-height: 60px;
      color: #180767;
      width: 100%;
    }
  }
  .title {
    border-top: solid 1px #e0e0e0;
    padding: 5px;
    text-align: center;
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    a {
      font-size: 12px;
      line-height: 18px;
      color: var(--link);
      text-decoration: none;
      transition: color 0.2s linear;

      &:hover {
        color: var(--link-hover);
        text-decoration: underline;
      }
    }
  }

  .icon {
    font-size: 30px;
    position: relative;
    top: -2px;

    &.move-top {
      top: -5px;
    }
  }
}

@media (max-width: 1440px) {
  .overview-item {
    .item {
      padding: 10px;
    }
  }
}
