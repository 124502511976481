.product-attr-page {
  .filter-icon {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: var(--white);
    padding: 8px 10px;
    transition: background 0.2s linear;

    &:hover,
    &.active {
      background-color: #f4f4f4;
    }

    svg {
      width: 14px;
      height: 14px;
      display: block;
    }
  }

  .min-width-100px {
    min-width: 100px;
  }

  .btn-add-option {
    transition: all 0.2s linear;

    &:hover {
      color: var(--primary-hover);
    }
  }

  .table-product-attributes {
    border-block: 1px solid rgba(0, 0, 0, 0.4);

    th {
      vertical-align: top;
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }

    td {
      vertical-align: top;
    }

    .info-item {
      tr {
        border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

        &:hover {
          background-color: #f8f8f8;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  .form-check-input {
    border-color: #e7e7e7;
  }

  .form-heading {
    &:hover {
      color: var(--primary-hover);
    }
  }
}
