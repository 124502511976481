.loading {
  img {
    animation: loading 1s linear infinite;
  }
}

@keyframes loading {
  to {
    transform: rotate(-360deg);
  }
}
