.btn-customer-group {
  .back {
    &--button {
      background-color: transparent;
      border-radius: 25px;
      border: solid 1px var(--primary);
      font-size: 14px;
      font-weight: 500;
      &:hover {
        background-color: var(--primary-hover);
        > a {
          color: #fff;
        }
      }
    }
  }
}

.tab-wrapper {
  // padding: 10px 0 25px;
  // border-bottom: 1px solid #f0f0f0;
  .tabs {
    &--item {
      &.active {
        position: relative;

        &::before {
          position: absolute;
          content: '';
          inset: calc(100% - 4px) 10% 0;
          height: 4px;
          border-radius: 50rem;
          background-color: var(--primary);
        }
      }
    }
  }
}

.svg-inline--fa.icon {
  color: #00000099;
  margin-top: 0px;
  padding: 0.85rem 0.95rem;
  &-billing {
    color: #00000099;
    padding: 0.85rem 1.1rem;
    margin-top: 0px;
  }
  &-shipping {
    color: #00000099;
    margin-top: 0px;
    padding: 0.85rem 1rem;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.status {
  .text-status {
    display: list-item;
    margin-left: 1.1em;
  }
}

.calendar {
  width: 55px;
  margin: 0px auto;
  border: 1px solid #d5d5d5;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  .month {
    text-transform: capitalize;
    font-size: 14px;
    padding: 5px;
    background-color: var(--primary);
    color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .date {
    font-size: 28px;
    font-weight: 600;
    padding: 8px 0px 0px 0px;
  }
}

.person-info-title {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.text-display-inline {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
