#create-edit__product .cate-attr {
  .multi-select-wrap {
    max-width: 1000px;
  }

  .label-left {
    width: 135px;
  }

  .attr-list {
    display: grid;
    grid-template-columns: auto auto;
    width: fit-content;
    gap: 24px;

    h3 {
      max-width: 200px;
    }

    select {
      max-height: 48px;
      margin-block: auto;
    }
  }
}

@media (max-width: 1200px) {
  #create-edit__product .cate-attr {
    .attr-list {
      gap: 16px;
      grid-template-columns: auto;
    }
  }
}
