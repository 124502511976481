table {
  .table-w10 {
    width: 10%;
    white-space: normal;
  }
  thead {
    tr {
      td {
        border-right: 1px solid rgba(255, 255, 255, 0.15);
        &:last-child {
          border-right: none;
        }
        &.bg-primary {
          font-size: 14px;
          line-height: 21px;
          --bs-text-opacity: 1;
          color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
          padding: 1rem 0.5rem;
        }
        &.td-item {
          font-size: 14px;
          line-height: 21px;
          font-weight: 500;
          --bs-text-opacity: 1;
          color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
          padding: 1rem 0.5rem;
          background-color: (var(--primary));

          span {
            font-weight: 500;
          }
        }
      }
    }
  }
  tbody {
    tr:last-child td {
      border-bottom: none !important;
    }

    td {
      &.td-item {
        vertical-align: middle;
        border-right: 1px dashed rgba(0, 0, 0, 0.2);
        padding: 24px 0;
      }
      .product-image {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }

      .table-info {
        p {
          font-size: 12px;
          line-height: 17px;
          color: var(--primary);
          white-space: normal;
          font-weight: 600;
        }

        span {
          font-size: 12px;
          line-height: 15px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
      &:last-child {
        border-right: none;
      }
    }
  }
}

.order-table {
  thead {
    // td:not(:first-child, :last-child) {
    //   border: 1px solid rgba(255, 255, 255, 0.16);
    // }
    td {
      .custom-checkbox input:checked ~ .checkmark {
        border: 1px solid #fff;
        background-color: var(--primary);
      }
      .checkmark {
        top: -20px;
        left: 10px;
        border: 1px solid #fff;
        background: var(--primary);
      }
    }
  }
  tbody {
    tr:nth-child(2n) {
      background-color: #f5f9fe;
    }
    td:last-child {
      border-right: none;
    }
    td {
      .custom-checkbox input:checked ~ .checkmark {
        border: 1px solid #888;
        background-color: var(--primary);
      }
      .checkmark {
        top: -11px;
        left: 10px;
        border: 1px solid #000;
        background: #fff;
      }
    }
  }
}

.custom-input-checkbox {
  display: block;
  height: 20px;
  width: 20px;
  margin: 0 auto;
  border-radius: 10%;
}

.checkbox-all-custom .checkmark {
  transform: translate(-50%, -50%);
  left: 50%;
  border: 1px solid white !important;
  // &::after {
  //   border: var(--primary) !important;
  // }
}
.checkbox-sizing {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
